<template>
  <Page :hasBg="false" style="background: #fff;">
    <NavBar title="认证结果" />
    <!-- 活体认证结果页面 -->
    <div class="px-32 pt-120">
      <div class="text-center">
        <img
          v-if="isPass"
          style="height: 96px; margin: 0 auto 20px auto"
          src="//wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/chatu_renzhen_success%403x.png"
          alt=""
        />
        <img
          v-else
          style="height: 96px; margin: 0 auto 20px auto"
          src="//wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/chatu_renzheng_fail%403x.png"
          alt=""
        />
        <template v-if="isPass">
          <p class="text-32 pb-32 text-primary" style="font-weight: 500">认证成功</p>
          <p class="pb-24 px-16 text-muted">{{ secondTime }}秒后自动跳转</p>
        </template>
        <template v-else>
          <p class="text-32 pb-32 text-danger" style="font-weight: 500">认证失败</p>
          <div class="p-32 mb-36 bg-block rounded-8" style="text-align: left">
            <p class="text-muted pb-24 text-28">常由于以下原因导致：</p>
            <p class="text-main text-28">{{ descriptionRef }}</p>
          </div>
        </template>
      </div>
      <div v-if="isPass" class="text-center pt-40">
        <Button plain hairline type="primary" @click="jumpToHome"
          >&nbsp;&nbsp;&nbsp;&nbsp;确 定&nbsp;&nbsp;&nbsp;&nbsp;</Button
        >
      </div>
      <div v-else>
        <div class="pb-32 mt-48">
          <Button block type="primary" @click="jumpToRetry">返回</Button>
        </div>
        <!-- <Button plain block type="primary" @click="jumpToLogout">退出认证</Button> -->
      </div>
    </div>
  </Page>
</template>
<script>
import { ref, defineComponent, onMounted } from '@vue/composition-api';
import { Button } from 'vant';
import NavBar from '@/components/NavBar/index.vue';
import ClientApp from '@/client/index.js';

export default defineComponent({
  components: {
    Button,
    NavBar
  },
  setup(_, ctx) {
    const secondTime = ref(3);
    const interval = ref();
    const descriptionRef = ref('');
    const isPass = ref(false);
    let query = {};
    const intervalTime = () => {
      interval.value = setInterval(() => {
        secondTime.value--;
        if (Number(secondTime.value) < 1) {
          clearInterval(interval.value);
          jumpToHome();
        }
      }, 1000);
    };

    const jumpToHome = () => {
      // 前往首页
      ClientApp.jumpNativePage(0, 0, 'main');
    };
    const jumpToRetry = () => {
      //重新认证 关掉
      ClientApp.finish();
    };

    onMounted(() => {
      const routeQuery = ctx.root.$route.query || {};
      query = { ...routeQuery };
      if (query.description) {
        descriptionRef.value = decodeURI(query.description);
      }

      isPass.value = query.pass === 'true';
      if (query.pass === 'true') {
        intervalTime();
      }
    });
    return {
      jumpToHome,
      jumpToRetry,
      secondTime,
      descriptionRef,
      isPass
    };
  }
});
</script>
<style scoped></style>
